import React from 'react';
import Api from '../Api';
import redirect from '../Global/Redirect';

function SignIn() {
  const signInAction = async () => {
    const fields = {
      name: document.querySelector('#sign-in > #name').value,
      email: document.querySelector('#sign-in > #email').value,
      password: document.querySelector('#sign-in > #password').value,
    };

    const resp = await Api.call({ url: `https://www.puffinstudios.repl.co/users`, method: 'POST', data: fields });

    if (resp?.secret_id) {
      localStorage.setItem('user-id', resp.secret_id);
      redirect('/')
    }
  };

  return (
    <div id="sign-in">
      <h1>Sign In</h1>

      <b>Name:</b>
      <input id="name" />
      <br /><br />

      <b>Email:</b>
      <input id="email" />
      <br /><br />

      <b>Password:</b>
      <input type="password" id="password" />
      <br /><br />

      <button onClick={signInAction}>Sign In</button>
    </div>
  );
}

export default SignIn;