import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from 'styled-components';
import useInterval from './useInterval';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingText = styled.div`
  font-size: 25px;
  margin-left: 10px;
`;

export default function FullPageSpinner() {
  const [loadingText, setLoadingText] = useState('Loading.');
  const [dotAmount, setDotAmount] = useState(0);
  const [dotDirection, setDotDirection] = useState('left');

  useInterval(() => {
    if (dotAmount === 3) setDotDirection('left');
    else if (dotAmount === 0) setDotDirection('right');

    if (dotDirection === 'right') {
      setLoadingText(loadingText + '.');
      setDotAmount(dotAmount + 1);
    } else {
      setLoadingText(loadingText.slice(0, -1));
      setDotAmount(dotAmount - 1);
    }
  }, 400);

  return (
    <Wrapper>
      <FontAwesomeIcon icon="fas fa-gear" size="2x" spin/>
      <LoadingText>{loadingText}</LoadingText>
    </Wrapper>
  );
}
