const API = {
  dataToUrl: (data) => {
    let urlData = [];

    const values = Object.values(data);

    Object.keys(data).forEach((key, keyIdx) => {
      urlData += `${key}=${values[keyIdx]}&`;
    });

    return `?${urlData}`;
  },

  call: async ({ url, method = 'GET', data = {} }) => {
    const fetchData = {
      method,
      headers: new Headers({
        'Content-Type': 'application/json; charset=UTF-8'
      }),
      referrer: "about:client",
    };

    if (method !== 'GET') {
      fetchData.body = JSON.stringify(data);
    } else {
      url += API.dataToUrl(data);
    }


    return await fetch(url, fetchData).then((r) => r.json());
  }
};

export default API;
