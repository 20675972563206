import React, { useEffect, useState } from 'react';
import FullPageSpinner from "./Global/FullPageSpinner";
import Nav from './Nav';
import Auth from './Auth/Auth';

function Page(props) {
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
  const [currentUser, setCurrentUser] = useState();

  const getUser = async () => {
    const user = await Auth.getCurrentUser();
    setCurrentUser(user);
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (currentUser !== undefined) setShowLoadingSpinner(false);
  }, [currentUser]);

  return (
    <div>
      {!showLoadingSpinner && <Nav user={currentUser} />}
      {showLoadingSpinner && <FullPageSpinner />}
      {!showLoadingSpinner && <props.ComponentPage user={currentUser} />}
    </div>
  );
}

export default Page;