import React from "react";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Page from "./components/Page";
import Games from "./components/Games";
import Dashboard from "./components/Auth/Dashboard";
import LogIn from './components/Auth/LogIn';
import SignIn from "./components/Auth/SignIn";

function App() {
  library.add(fas);

  return (
    <Routes>
      <Route path="/" element={<Page ComponentPage={Home} />} />
      <Route path="/games" element={<Page ComponentPage={Games} />} />
      <Route path="/dashboard" element={<Page ComponentPage={Dashboard} />} />
      <Route path="/login" element={<Page ComponentPage={LogIn} />} />
      <Route path="/signin" element={<Page ComponentPage={SignIn} />} />
    </Routes>
  );
}

export default App;
