import React, { useState } from 'react';
import Api from '../Api';
import redirect from '../Global/Redirect';

function LogIn() {
  const [errors, setErrors] = useState([]);

  const logInAction = async () => {
    const fields = {
      email: document.querySelector('#log-in > #email').value,
      password: document.querySelector('#log-in > #password').value,
    };

    console.log('ji');

    setErrors([])

    const resp = await Api.call({ url: 'https://www.puffinstudios.repl.co/users', data: fields });
    console.log(resp);

    if (resp?.id) {
      localStorage.setItem('user-id', resp.secret_id);

      redirect('/');
    } else {
      console.log('ya');
      setErrors(['Incorrect Email or Password']);
    }
  }

  return (
    <div id="log-in">
      <h1>Log in</h1>
      <b>Email:</b>
      <input id="email" />
      <br /><br />

      <b>Password:</b>
      <input type="password" id="password" />
      <br /><br />

      <button onClick={logInAction}>Log in</button>

      {errors.map((error, errorIdx) => (
        <div key={`error-${errorIdx}`}>
          {error}
        </div>
      ))}
    </div>
  );
}

export default LogIn;

