import Api from '../Api';
import redirect from '../Global/Redirect';

const getCurrentUser = async () => {
  let userId = localStorage.getItem('user-id');

  if (userId === null) userId = '';

  return await Api.call({ url: 'https://www.puffinstudios.repl.co/users/', data: { id: userId } });
};

const logOut = () => {
  localStorage.removeItem('user-id');
  redirect('/');
};

export default {
  getCurrentUser,
  logOut
}
