import React from 'react';
import Auth from './Auth';

function Dashboard(props) {
  const {
    user
  } = props;

  return (
    <div>
      Hello, {user?.name}!

      <button onClick={() => Auth.logOut()}>Log Out</button>
    </div>
  );
}

export default Dashboard;