
import React from 'react';
import { Link } from 'react-router-dom';

export default function Nav(props) {
  const { user } = props;

  console.log(user);

  return (
    <div>
      <Link to="/">Puffin Studios</Link>
      <Link to="/games">Games</Link>

      {Object.keys(user).length === 0 && <Link to="/signin">Sign In</Link>}
      {Object.keys(user).length === 0 && <Link to="/login">Log In</Link>}

      {Object.keys(user).length > 0 && <Link to="/dashboard">Dashboard</Link>}
      {Object.keys(user).length > 0 && user.role === 'admin' && <Link to="/admin">Admin</Link>}
    </div>
  );
}
